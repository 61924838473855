




























































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { NeedTrainingStatus } from '@/api/interfaces/needtrainingstatus'

export default defineComponent({
  name: 'MassChangeStatusForm',
  components: {
    ConfirmChanges: () => import('./ConfirmChanges.vue'),
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data () {
    return {
      dialogConfirmChanges: false,
      isSubmiting: false,
      status: {} as NeedTrainingStatus,
    }
  },
  computed: {
    ...mapState('status', {
      statusesList: 'list',
    }),
    ...mapGetters('filterList', {
      filters: 'getFiltersParams',
    }),
    ...mapState('changeStatusForm', {
      totalItem: 'totalItem',
      statusChanged: 'statusChanged',
    }),
    ...mapState('traineeList', {
      totalNeedTrainings: 'totalItems',
    }),
    getMessage (): string {
      return this.$t('dialog.message_confirmation_mass_change_need_training_status', {
        number: this.totalNeedTrainings,
        status: this.status ? this.status.status : null,
      }) as string
    },
  },
  methods: {
    ...mapActions('changeStatusForm', {
      massChangeStatus: 'massChangeStatus',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
      sendWarningMessage: 'sendWarningMessage',
    }),
    openDialogConfirmChanges () {
      this.$emit('close')
      this.dialogConfirmChanges = true
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    clear () {
      this.getValidationObserver().reset()
      this.status = {}
      this.dialogConfirmChanges = false
    },
    close () {
      this.clear()
      this.$emit('close')
    },
    async submit () {
      this.isSubmiting = true
      try {
        await this.massChangeStatus({ statusIri: this.status['@id'], filters: this.filters })
        this.close()
        this.$emit('reload')

        const values = { statusChanged: this.statusChanged, totalItem: this.totalItem }
        const message = this.$t('trainee.form.formation.change_status.sended', values)

        if (this.statusChanged === this.totalItem) {
          await this.sendSuccessMessage(message)
        } else {
          await this.sendWarningMessage(message)
        }
      } finally {
        this.isSubmiting = false
      }
    },
  },
})
